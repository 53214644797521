nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

.all-logs, .user-logs, .wallet-logs, .dashboard-users-logs, .dashboard-wallets-logs {
  width: 350px;
  text-wrap: wrap;
}

h3 {
  text-align: center;
}

.all-logs h3, .dashboard-users-logs h3, .dashboard-wallets-logs h3 {
  margin-bottom: 50px;
}

.log {
  border: 1px solid #000;
  margin: 10px 0;
  padding-left: 10px;
  padding-top: 10px;
}

.log-input, .log-output {
  margin-left: 10px;
}

.wrapper-columns {
  display: flex;
  gap: 20px;
}

.user-search, .wallet-search {
  text-align: center;
}

.user-search input, .wallet-search input {
  width: 200px;
}

table tbody tr td {
  border-bottom: 1px solid #000 !important;
  padding: 10px;
}

.filters {
  /* width: 60%; */
  text-align: center;
  margin-top: 100px;
}

.filters label {
  display: inline-block;
  margin: 10px 20px;
}

.table-wrapper {
  border: 1px solid #000;
  margin: 10px 0;
}

.table, .table-wrapper {
  max-height: 400px !important;
  overflow-y: scroll;
}

.td-data {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  animation: data-change 1s;
}

@keyframes data-change {
  0% {
    background-color: purple;
  }

  100% {
    background-color: transparent;
  }
}

/* Styles from the simulation repo */
body,
input,
td,
th {
  font-family: sans-serif;
  font-size: 14px;
}
.top {
  position: fixed;
  top: 0;
  width: calc(100% - 20px);
  background: lightyellow;
  z-index: 5;
  padding: 2px;
}
span[title]:hover,
label[title]:hover {
  cursor: help;
  background-color: yellow;
}
button.icon {
  border: 0;
  background: transparent;
  padding: 0;
}
button:not(.icon) {
  font-size: 0.7em;
  margin: 3px 1px 0 0;
}
input:not([type="checkbox" i]) {
  width: 50px;
}
label {
  margin-right: 6px;
}
form {
  margin: 0;
  float: right;
}
table {
  margin-top: 30px;
}
td {
  padding: 2px;
}
tbody tr:hover {
  background-color: #fafafa;
}
th.quest {
  min-width: 300px;
}
th.main {
  min-width: 240px;
}

.gap-xs {
  display: inline-block;
  width: 5px;
}

.gap-sm {
  display: inline-block;
  width: 15px;
}

.gap-md {
  display: inline-block;
  width: 25px;
}

.gap-lg {
  display: inline-block;
  width: 50px;
}
td.active {
  background-color: rgba(0, 255, 0, 0.05);
}

.badge {
  color: white;
  font-weight: bold;
  font-size: 0.6em;
  padding: 2px;
  margin-right: 1px;
  border-radius: 3px;
  text-wrap: nowrap;
}
.badge-com {
  background-color: red;
}
.badge-ref {
  background-color: orange;
}
.badge-tvl {
  background-color: blue;
}
